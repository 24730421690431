<template>
  <v-app>
    <transition name="fade-in-up">
      <div class="row">
        <div class="col-md-12">
          <strong>ARECO SQUASH CLUB</strong><br>
          <strong>REGLAMENTO: RANKING DE SQUASH</strong><br><br>

          <v-chip dark>1.</v-chip>  El “Ranking de Squash” (en adelante “Ranking”) tiene como finalidad fomentar la práctica deportiva, facilitar el encuentro entre jugadores del mismo nivel, estimular la superación personal y proporcionar orientación a los capitanes de los equipos compitiendo en representación del Club.<br>
          <br>
          <v-chip dark>2.</v-chip>  El Ranking tendrá carácter anual, comenzará en marzo y finalizará en noviembre del año en curso. La inscripción en el año inaugural es totalmente gratuita para los socios del “Areco Squash Club” (en adelante “el Club”).<br>
          <br>
          <v-chip dark>3.</v-chip>  Los participantes no recibirán, por su participación, ningún premio en metálico. No obstante, la participación es de carácter obligatorio para aquellos jugadores que quieran competir en representación del Club.<br>
          <br>
          <v-chip dark>4.</v-chip>  El administrador y/o los capitanes de equipo se reservan el derecho de modificar la normativa en aras del buen funcionamiento del ranking.<br>
          <br>
          <br>
          <strong>**Sobre los participantes**</strong>
          <br>
          <v-chip dark>5.</v-chip>  Para participar, se requiere disponer de una dirección de correo electrónico activa y que se consulte habitualmente, ya que todas las comunicaciones se harán por este medio.<br>
          <br>
          <v-chip dark>6.</v-chip>  La inscripción se formalizará a través de un formulario web que se distribuirá por los distintos medios de comunicación de la comunidad de Squash del Club.<br>
          <br>
          <v-chip dark>7.</v-chip>  La inscripción tendrá una fecha límite, la cual será anunciada con anticipación. En aquellos casos en que el participante se inscriba pasada la mencionada fecha de vencimiento, quedará a criterio del administrador y/o el equipo de capitanes la validez de la inscripción o la cantidad de puntos que se le otorguen a ese jugador en el ranking.<br>
          <br>
          <strong>**Sobre el administrador y/o Capitanes de Equipos**</strong>
          <br>
          <v-chip dark>8.</v-chip>  El ranking está a cargo de un administrador bajo las instrucciones del equipo de capitanes.<br>
          <br>
          <v-chip dark>9.</v-chip>  Las funciones del administrador y del equipo de capitanes son:<br>
          - Animar a la participación.<br>
          - Resolver en primera instancia los conflictos y las dudas que se produzcan entre los jugadores, así como atender las sugerencias para el mejor funcionamiento del ranking.<br>
          - Administrar las altas y las bajas de jugadores o la modificación del reglamento.<br>
          - Interpretar la normativa.<br>
          - Determinar, en caso de conflicto, las condiciones para celebrar una partida.<br>
          <br>
          <br>
          <strong>**Sobre el ranking**</strong>
          <br>
          <v-chip dark>10.</v-chip>  Cada jugador tendrá, al inicio de cada fase, un número de ranking personal que estará en función de los resultados obtenidos en la fase anterior, y del número de jugadores participantes. Al inicio de cada año, se le otorgará una diferencia de 10 puntos entre cada jugador, siendo que el último de la tabla comenzará con 10 puntos.<br>
          <br>
          <v-chip dark>11.</v-chip>  El ranking estará publicado en la dirección web http://arecosquash.com.ar/<br>
          <br>
          <v-chip dark>12.</v-chip>  El ranking personal podrá determinar la inclusión del jugador en un equipo.<br>
          <br>
          <v-chip dark>13.</v-chip>  En el caso de jugadores que se incorporan a la Liga por primera vez, antes de iniciar una fase, el equipo de capitanes realizará al nuevo jugador una “prueba de nivel” para calcular su ranking inicial, que se estimará a la baja. En caso de que no realice la prueba de nivel, se incorporará en el último lugar.<br>
          <br>
          <br>
          <strong>**Sobre los partidos y los desafíos**</strong>
          <br>
          <v-chip dark>14.</v-chip>  Cada jugador tendrá acceso al ranking mediante un usuario y contraseña personal. Una vez que ingrese al sistema, podrá desafiar a los tres jugadores que estén en las primeras tres posiciones sobre su ranking personal.<br>
          <br>
          <v-chip dark>15.</v-chip>  El desafío podrá ser solicitado solo los viernes y sabados de cada semana. El jugador desafiado recibirá un correo electrónico con la notificación del desafío y tendrá 24 horas para aceptar o rechazar el desafío.<br>
          <br>
          <v-chip dark>16.</v-chip>  Aceptar un desafio implica tener disponibilidad para jugar el sábado, domingo, lunes o martes subsiguientes al desafío.<br>
          <br>
          <v-chip dark>17.</v-chip>  Los jugadores no podrán realizar ni recibir más de un desafío por fecha. En consecuencia, si reciben un desafío, no podrán desafiar a otro jugador hasta tanto no rechacen el desafío que recibieron.<br>
          <br>
          <v-chip dark>18.</v-chip>  Los partidos se jugarán con pelota de dos puntos amarillos.<br>
          <br>
          <v-chip dark>19.</v-chip>  El saque del primer set del partido se decidirá por sorteo y para el resto de los sets sacará el jugador ganador del set anterior.<br>
          <br>
          <v-chip dark>20.</v-chip>  Un partido se juega al mejor de 5 sets.<br>
          <br>
          <v-chip dark>21.</v-chip>  Los sets se juegan al mejor de 11 puntos sacando dos de diferencia. En caso de producirse un empate a 10, ganará el set aquel jugador que consiga aventajar al rival en dos puntos.<br>
          <br>
          <v-chip dark>22.</v-chip>  Una vez comenzado el partido, en caso de que finalice el tiempo previsto para la disputa o el partido deba ser pospuesto por fuerza mayor, sin que ésta hubiera acabado, ambos jugadores acordarán una nueva cita. El partido se reanudará manteniendo los sets finalizados, y 0-0 el siguiente set, omitiendo por tanto los puntos del set en el que se interrumpió la partida.<br>
          <br>
          <v-chip dark>23.</v-chip>  Una vez acabado un partido, el jugador que desafió deberá ingresar al sistema e informar el resultado en el plazo más breve posible, y siempre antes del final de la fecha. Si no se ingresan los resultados antes del miércoles siguiente a la fecha del desafio, el sistema otorgará 0 puntos a cada jugador.<br>
          <br>
          <v-chip dark>24.</v-chip>  Para todos los aspectos no contemplados en estas normas, los partidos se regirán por las reglas del juego mundial de squash singles.<br>
          <br>
          <br>
          <strong>**Sobre la clasificación dentro de cada grupo**</strong>
          <br>
          <v-chip dark>25.</v-chip>  El orden de la clasificación se hará por puntos conseguidos.<br>
          <br>
          <v-chip dark>26.</v-chip>  Los jugadores obtienen 1 punto por cada set ganado más 1 punto para el ganador del partido. Los resultados son los siguientes:<br>
          - Partido disputado y con resultado de 3/0 = 4 puntos al ganador y 0 punto al perdedor.<br>
          - Partido disputado y con resultado de 3/1 = 4 puntos al ganador y 1 punto al perdedor.<br>
          - Partido disputado y con resultado de 3/2 = 4 puntos al ganador y 2 puntos al perdedor.<br>
          - Partido no disputado por inasistencia del rival 3/0 = 4 puntos al ganador y 0 punto al perdedor.<br>
          - WO del rival 3/0 = 4 puntos al ganador y 0 punto al perdedor.<br>
          <br>
          <v-chip dark>27.</v-chip>  En caso 3 desafíos rechazados no consecutivos anuales, se le otorgarán 3 puntos al jugador desafiante.<br>
          <br>
          <v-chip dark>28.</v-chip>  En caso de empate a puntos en el ranking, éste se deshace por los siguientes criterios (por orden):<br>
          - Diferencia total de sets ganados y perdidos.<br>
          - Mayor número de partidas jugadas.<br>
          - Mayor número de sets ganados.<br>
          <br>
          <br>
          <strong>**Sobre las altas y bajas en la Liga**</strong>
          <br>
          <v-chip dark>29.</v-chip>  Los jugadores podrán darse de baja voluntariamente en cualquier momento, por lesión u otra circunstancia, comunicándolo al administrador.<br>
          <br>
          <v-chip dark>30.</v-chip>  El administrador, bajo instrucciones del equipo de capitanes, podrá dar de baja “de oficio” a un jugador, sin previo aviso, si aprecian objetivamente falta de interés u otras circunstancias que vayan en detrimento de los jugadores o del ranking en general.<br>

        </div>
      </div>
    </transition>
  </v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import { mapGetters } from "vuex";

export default {
  components: {
  },

  data: () => ({
    uri: "regulation",
  }),

  created() {
    this.initialize();
  },

  methods: {
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Reglamento" }]);
  },

  computed: {
  },
};
</script>
